exports.components = {
  "component---src-components-documentaries-item-js": () => import("./../../../src/components/documentariesItem.js" /* webpackChunkName: "component---src-components-documentaries-item-js" */),
  "component---src-components-kids-item-js": () => import("./../../../src/components/kidsItem.js" /* webpackChunkName: "component---src-components-kids-item-js" */),
  "component---src-components-tvseries-item-js": () => import("./../../../src/components/tvseriesItem.js" /* webpackChunkName: "component---src-components-tvseries-item-js" */),
  "component---src-components-view-js": () => import("./../../../src/components/view.js" /* webpackChunkName: "component---src-components-view-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-documentaries-js": () => import("./../../../src/pages/documentaries.js" /* webpackChunkName: "component---src-pages-documentaries-js" */),
  "component---src-pages-for-kids-js": () => import("./../../../src/pages/for-kids.js" /* webpackChunkName: "component---src-pages-for-kids-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-other-js": () => import("./../../../src/pages/other.js" /* webpackChunkName: "component---src-pages-other-js" */),
  "component---src-pages-tv-series-js": () => import("./../../../src/pages/tv-series.js" /* webpackChunkName: "component---src-pages-tv-series-js" */)
}

